.react-datepicker {
  @apply relative grid grid-cols-1 gap-10 md:grid-cols-2;
}
.react-datepicker__aria-live {
  @apply hidden;
}

.react-datepicker__month-container {
  @apply text-center;
}

.react-datepicker__current-month {
  @apply text-sm font-semibold text-gray-900 dark:text-gray-100;
}

.react-datepicker__day-names {
  @apply mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500 dark:text-gray-400;
}

.react-datepicker__month {
  @apply mt-5;
}

.react-datepicker__week {
  @apply grid grid-cols-7 my-0.5;
}

.react-datepicker__day {
  @apply w-full flex items-center justify-center cursor-pointer empty:opacity-0 empty:cursor-auto empty:hover:bg-transparent text-sm;
  @apply empty:bg-transparent #{!important};

  span {
    @apply rounded-full hover:bg-gray-100 dark:hover:bg-gray-700/60;
  }

  &--in-range,
  &--in-selecting-range {
    @apply bg-gray-100 dark:bg-gray-700/50 rounded-none;
  }

  &--range-start,
  &--selecting-range-start {
    @apply rounded-l-full;
  }
  &--range-end,
  &--selecting-range-end {
    @apply rounded-r-full;
  }

  &--selected,
  &--range-start,
  &--range-end,
  &--selecting-range-start,
  &--selecting-range-end {
    span {
      @apply bg-primary-500 dark:bg-primary-500 text-white rounded-full hover:bg-primary-500 dark:hover:bg-primary-500;
    }
  }

  &--disabled {
    @apply text-neutral-400 dark:text-neutral-500;
  }
}

.react-datepicker__day_span {
  @apply flex h-[46px] w-[46px] items-center justify-center rounded-full;
}

.smallcalendar .react-datepicker__day_span {
  @apply flex h-[33px] w-[33px] items-center justify-center rounded-full;
}

.timepick .react-datepicker__time-container {
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  padding: 0px 0px;
  // max-height: 150px;
  background-color: #fff;
  animation-duration: 1s;
  border: solid 1px rgb(79, 70, 229);
}

.timepick .react-datepicker__time-container {
  animation: growDown 300ms ease;
}

.timepick
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  &::-webkit-scrollbar {
    background-color: rgb(236, 236, 236);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(142, 142, 142);
    -webkit-border-radius: 5px !important;
    border-radius: 5px !important;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px !important;
  }
}

.dark
  .timepick
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  &::-webkit-scrollbar {
    background-color: rgb(33, 49, 82);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(142, 142, 142);
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px !important;
  }
}

.timepick .react-datepicker-time__header {
  display: none;
}

.dark .timepick .react-datepicker__time-container {
  background-color: rgb(17, 24, 39);
  border: none;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(1);
  }
}

.timepick .react-datepicker__time-list-item {
  padding: 3px 0;
}

.timepick .react-datepicker.react-datepicker--time-only {
  display: block;
  width: 100%;
}

.react-datepicker__tab-loop .react-datepicker-popper {
  width: 100%;
}

.timepick .react-datepicker__tab-loop {
  position: relative;
}

.timepick .react-datepicker__tab-loop {
  height: 160px;
}

.timepick .react-datepicker-popper {
  top: 1% !important;
  left: 0% !important;
  transform: initial !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}
.timepick
  li.react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
  background-color: rgb(79, 70, 229);
  color: #fff;
}

.dark
  .timepick
  li.react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
  background-color: rgb(64, 98, 165);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  // height: calc(195px + (1.7em / 2));
  // border-top: solid 1px rgb(64, 98, 165);
  height: calc(145px + (1.7em / 2));
  overflow-y: scroll;
}

li.react-datepicker__time-list-item.react-datepicker__time-list-item--disabled {
  color: #b9b9b9;
}

.dark
  li.react-datepicker__time-list-item.react-datepicker__time-list-item--disabled {
  color: #444;
}

/* addListingDatePickerExclude */
.addListingDatePickerExclude {
  .react-datepicker__day {
    &--disabled {
      @apply relative bg-gray-100 dark:bg-gray-700/50 rounded-none empty:opacity-0;
      &::after {
        content: "";
        @apply block h-full absolute border-l border-neutral-300 dark:border-neutral-700 rotate-45;
      }
    }
  }
}
